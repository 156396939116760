import CONFIG from "./config";

import useModal from "./hooks/useModal";
import useCount from "./hooks/useCount";

import setCountPrice from "./helpers/setCountPrice";

const personCountHandler = () => {
  const modal = useModal()
  const count = useCount();
  const places = modal.dataset

  count.value = CONFIG.USER_COUNT

  modal.addEventListener('click', function (e) {
    if (e.target.id === 'order-modal-dec') {
      if (Number(count.value) > 1) {
        setCountPrice(Number(count.value) - 1)
      }
    }

    if (e.target.id === 'order-modal-inc') {
      if (Number(count.value) < Number(places.max)) {
        setCountPrice(Number(count.value) + 1)
      }
    }
  })
}

export default personCountHandler
