import menu from './components/menu';
import dropdown from './components/dropdown'
import gamesSlider from './components/games-slider'
import scrollXToCenter from './components/scroll-x-to-center'
import tabs from './components/tabs'
import marquee from './components/marquee'
import accordion from './components/accordion'
import copyCenterGallerySlider from './components/copy-center-gallery'
import menuTabSlider from './components/menu-tab-slider'
import galleryScrollScene from './components/gallery-scroll';
import galleryMasonry from './components/gallery-masonry'
import gameZoneSlider from './components/game-zone-slider'
import responsiveTabs from './components/responsive-tabs'
import blockFadeInAnimation from './components/block-fade-in-animation'
import resumeUpload from './components/upload'
import anchorScroll from './components/anchor-scroll';
import modals from './components/modals';
import seo from './components/seo';
import scheduler from './components/scheduler/scheduler';

menu();
dropdown();
gamesSlider();
scrollXToCenter();
tabs('.js-price-section-tab', '.js-price-section-content');
tabs('.js-game-zone-section-tab', '.js-game-zone-section-content');
tabs('.js-tabs-section-item', '.js-tabs-section-content');
tabs('.js-contact-section-map-tab', '.js-contact-section-map-content');
tabs('.js-vacancies-section-tab', '.js-vacancies-section-content');
tabs('.js-menu-section-content-tab', '.js-menu-section-map-content');
marquee();
accordion('.js-copy-service-section-item', '.js-copy-service-section-content');
copyCenterGallerySlider();
menuTabSlider();
galleryScrollScene();
galleryMasonry();
gameZoneSlider();
responsiveTabs();
blockFadeInAnimation();
resumeUpload();
anchorScroll('.js-anchor-scroll', $('header').height() + 24);
modals();
seo('.js-seo-button', '.js-seo-body');
scheduler()
