import useTotal from "./hooks/useTotal";
import useListError from "./hooks/useListError";
import postData from "./postData";

const validate = (mask) => {
  const payment = useTotal()
  const listError = useListError()

  const form = document.getElementById('order-modal-form')
  const tel = document.getElementById('order-modal-tel')
  const name = document.getElementById('order-modal-name')

  name.addEventListener('focus', function (){
    this.removeAttribute('style')
  })

  tel.addEventListener('focus', function (){
    this.removeAttribute('style')
  })

  form.addEventListener('submit', function (e){
    e.preventDefault();
    e.stopPropagation();

    const button = e.target.querySelector('[type="submit"]')

    if(payment.innerHTML === '0') {
      listError.style.borderColor = 'var(--red)'
    }

    if(name.value.length < 1) {
      name.style.borderColor = 'var(--red)'
      name.style.color = 'var(--red)'
    }

    if(String(mask.unmaskedValue).length < 12) {
      tel.style.borderColor = 'var(--red)'
      tel.style.color = 'var(--red)'
    }

    if(payment.innerHTML !== '0' && name.value.length > 0 && String(mask.unmaskedValue).length === 12) {
      button.classList.add('is-preloaded')
      postData(e.target);
    }
  })
}

export default validate
