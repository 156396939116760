import $ from 'jquery'

const tabs = (tabsSelector, contentSelector) => {
  $(tabsSelector).on('click', function () {
    const $content = $(contentSelector).eq($(this).index());

    $(this).addClass('is-active').siblings().removeClass('is-active');
    $content.addClass('is-active').siblings().removeClass('is-active');

    if($content.find('*').hasClass('slick-slider')) {
      $content.find('.slick-slider').slick('refresh');
    }

    if($content.hasClass('js-centered-block-wrapper') || $content.find('*').hasClass('js-centered-block-wrapper')) {
      $(window).trigger('resize');
    }

    if($(this).parents().hasClass('js-responsive-tab')) {
      $(this).parents('.js-responsive-tab').attr("data-title", $(this).text())
    }
  })
};

export default tabs;
