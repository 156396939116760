import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded'

const galleryMasonry = () => {
  const grid = document.getElementById('js-gallery-masonry');
  if(grid) {
    const msnry = new Masonry(grid, {
      columnWidth: 20,
      gutter: 20,
      fitWidth: true,
      horizontalOrder: true
    });

    imagesLoaded(grid).on( 'progress', function() {
      msnry.layout();
    });
  }

};

export default galleryMasonry;
