import CONFIG from "./config";

import useList from "./hooks/useList";
import useCount from "./hooks/useCount";
import useTotal from "./hooks/useTotal"
import useListError from "./hooks/useListError";

import setMaxModalDataset from "./helpers/setMaxModalDataset";
import setCountPrice from "./helpers/setCountPrice";

const calculate = (maxPlaces) => {
  const list = useList();
  const input = useCount();
  const total = useTotal();
  const listError = useListError();
  const minPlaces = []
  const timeArr = []

  const checkboxGroup = list.querySelectorAll('input[type="checkbox"]')

  input.value = String(CONFIG.USER_COUNT)
  total.innerHTML = String(CONFIG.PAYMENT)

  setMaxModalDataset(maxPlaces)

  checkboxGroup.forEach((checkbox) => {
    checkbox.addEventListener('change', function(){
      const payment = Number(total.innerHTML)
      const places = Number(input.value)
      const currentTime = Number(checkbox.dataset.time)
      const currentTariff = Number(checkbox.dataset.tarif)

      if(checkbox.checked){
        total.innerHTML = String(payment + (currentTariff * places))
        minPlaces.push(Number(checkbox.dataset.places))
        timeArr.push(Number(checkbox.dataset.time))

        if(places > Math.min(...minPlaces)) {
          setCountPrice(Math.min(...minPlaces))
        }
      } else {
        total.innerHTML = String(payment - (currentTariff * places))
        minPlaces.splice(minPlaces.indexOf(Number(checkbox.dataset.places)), 1)
        timeArr.splice(timeArr.indexOf(Number(checkbox.dataset.time)), 1)
      }

      if(timeArr.length === 2) {
        const difference = Math.abs(timeArr.reduce((acc, item) => acc - item))

        if(difference !== 1) {
          const lastCheckbox = list.querySelector(`[value="${timeArr.filter(item => item !== currentTime)[0]}"]`)
          lastCheckbox.checked = false
          total.innerHTML = String((currentTariff * places))

          minPlaces.splice(minPlaces.indexOf(Number(lastCheckbox.dataset.places)), 1)
          timeArr.splice(timeArr.indexOf(Number(lastCheckbox.dataset.time)), 1)
        }
      }

      if(timeArr.length === 3) {
        const currentChecked = Number(timeArr.filter(z => z === currentTime)[0])
        const filtered = timeArr.filter(k => k !== currentChecked)
        const reduced = filtered.reduce((acc, item) => acc + item) / 2
        const difference = reduced - currentChecked

        if(Math.abs(difference) === 1.5) {
          const ref = difference < 0 ? Math.min(...timeArr) : Math.max(...timeArr)
          const lastCheckbox = list.querySelector(`[value="${ref}"]`)

          lastCheckbox.checked = false
          timeArr.splice(timeArr.indexOf(ref), 1)

          const sum = []
          timeArr.forEach(item => {
            const ch = list.querySelector(`[value="${item}"]`)
            sum.push(Number(ch.dataset.tarif) * Number(input.value))
          })

          total.innerHTML = String(sum.reduce((acc, item) => acc + item))
          minPlaces.splice(minPlaces.indexOf(Number(lastCheckbox.dataset.places)), 1)

        } else {
          minPlaces.splice(0, 2)
          timeArr.splice(0, 2).forEach(item => {
            const ch = list.querySelector(`[value="${item}"]`)
            ch.checked = false
          })
          total.innerHTML = String((currentTariff * Number(input.value)))
        }
      }

      if(minPlaces.length === 0) {
        setMaxModalDataset(maxPlaces)
      } else {
        setMaxModalDataset(Math.min(...minPlaces))
      }

      listError.removeAttribute('style');
    })
  })
}

export default calculate
