import $ from 'jquery';
import "jquery.marquee"

const marquee = () => {
  $('.marquee').marquee({
    direction: 'left',
    duplicated: true,
    duration: 15000
  });
}

export default marquee;
