const useMaxPlaces = (currentDay, day, arr) => {
  const data = new Date()
  const filtered = currentDay === day ? {} : arr[day]

  Object.entries(arr[day]).forEach(([key, value]) => {
    if(key > data.getHours()) {
      filtered[key] = value
    }
  })

  return  Math.max(...Object.values(filtered))
}

export default useMaxPlaces
