import IMask from "imask";
import initCalendar from './initCalendar';
import validate from "./validate";

import useNextMonth from "./hooks/useNextMonth";
import useCurrentMonth from "./hooks/useCurrentMonth";
import useListError from "./hooks/useListError";

import mockData from "./mockData";
import personCountHandler from "./personCountHandler";
import useList from "./hooks/useList";

const scheduler = () => {
  const current = useCurrentMonth();
  const next = useNextMonth();
  const list = useList()

  const currentMonth = new Date().getMonth()
  const tel = document.getElementById('order-modal-tel')
  const name = document.getElementById('order-modal-name')
  const listError = useListError()

  const mask = IMask(tel, {
    mask: '+{38}(000)000-00-00',
    lazy: false,
    placeholderChar: '_'
  })

  function resetInputs() {
    name.value = ''
    mask.value = ''
    mask.updateValue()
    listError.removeAttribute('style')
    tel.removeAttribute('style')
    name.removeAttribute('style')
  }

  list.parentNode.addEventListener('scroll', function (e) {
    const that = e.target
    if(that.scrollLeft > 0) {
      that.parentNode.classList.add('has-left-shadow')
    } else {
      that.parentNode.classList.remove('has-left-shadow')
    }
  })

  function calendar(arr) {
    initCalendar(currentMonth, arr.currentMonth,  arr.workingHours, () => {
      resetInputs();
    });

    current.addEventListener('click', () => {
      initCalendar(currentMonth, arr.currentMonth, arr.workingHours, () => {
        resetInputs();
      });
    })

    next.addEventListener('click', () => {
      initCalendar(currentMonth + 1, arr.nextMonth, arr.workingHours, () => {
        resetInputs();
      });
    })

    validate(mask);
    personCountHandler();
  }

  fetch(`${window.location.origin}/count-booking`, {
    method: 'GET',
  })
    .then(response => response.json())
    .then(data => {
      calendar(data)
  })
    .catch(() => {
      calendar(mockData)
  })
}

export default scheduler;
