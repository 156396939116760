import $ from 'jquery';
import 'slick-slider';

const copyCenterGallerySlider = () => {
  $('.js-copy-center-gallery').each(function () {
    const $that = $(this);
    const $gallerySlider = $(this).find('.js-copy-center-gallery-slider');

    $gallerySlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      const i = (currentSlide || 0) + 1;

      $that.find('.js-copy-center-gallery-total').text(slick.slideCount);
      $that.find('.js-copy-center-gallery-length').text(`${i}`);
    });

    $gallerySlider.slick({
      prevArrow: $('.js-copy-center-gallery-arrow-left'),
      nextArrow: $('.js-copy-center-gallery-arrow-right'),
      dots: false,
      arrows: true,
      slidesToShow: 1.5,
      slidesToScroll: 1,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    });
  })
};

export default copyCenterGallerySlider;
