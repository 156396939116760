import {MESSAGES} from "./copyright";
import useLanguage from "./hooks/useLanguage";

const placesText = (num) => {
  const computed = Number(String(num).slice(-1))
  const lang = useLanguage()
  switch (lang) {
    case 'en':
      if(num === 1) {
        return MESSAGES[lang].PLACE_SINGLE
      }
      return MESSAGES[lang].PLACE_PLURAL

    default:
      if ((num === 0 || (num >= 11 && num <= 14)) || (num % 10 !== 1 && !(computed > 1 && computed < 5))) {
        return MESSAGES[lang].PLACE
      } if (num % 10 === 1 && num !== 11) {
      return MESSAGES[lang].PLACE_SINGLE
    }
      return MESSAGES[lang].PLACE_PLURAL
  }
}

export default placesText
