import $ from 'jquery';
import 'slick-slider';

const gamesSlider = () => {
  $('.js-games-block').each(function () {
    const $that = $(this);
    const $gamesSlider = $(this).find('.js-game-slider');

    $gamesSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      let i = (currentSlide || 0) + 1;
      if($(window).width() >= 768 && slick.slideCount % 3 === 0){
        i = (currentSlide || 0) + 3;
      }

      $that.find('.js-games-total').text(slick.slideCount);
      $that.find('.js-games-length').text(`${i}`);
    });

    $gamesSlider.slick({
      prevArrow: $that.find('.js-games-slider-arrow-left'),
      nextArrow: $that.find('.js-games-slider-arrow-right'),
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    });
  })

}

export default gamesSlider;
