import useModal from "../hooks/useModal";

const setMaxModalDataset = (num) => {
  const modal = useModal();
  const modalDataset = modal.dataset

  modalDataset.max = String(num)

  return false;
}

export default setMaxModalDataset
