import { ScrollScene } from 'scrollscene'
import { gsap } from 'gsap'

const galleryScrollScene = () => {
  const $items = $('.js-gallery-item');
  $items.each(function (index) {
    const that = this;
    const myScene = new ScrollScene({
      triggerElement: that,
      triggerHook: 0.5,
      gsap: {
        timeline: gsap.to(that, {x: index % 2 === 0 ? "-25%" : "25%", ease: 'power2.easeIn', paused: true}),
      },
      duration: 1000,
      offset: -100,
      breakpoints: { 0: false, 767: true }
    })
  })
}


export default galleryScrollScene;
