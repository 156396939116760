import $ from 'jquery';
import 'slick-slider';

const menuTabSlider = () => {
  $('.menu-tab__content').each(function () {
    const $that = $(this);
    const $menuSlider = $(this).find('.js-menu-tab-slider');

    $menuSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      const i = (currentSlide || 0) + 1;

      $that.find('.js-menu-tab-slider-total').text(slick.slideCount);
      $that.find('.js-menu-tab-slider-length').text(`${i}`);
    });

    $menuSlider.slick({
      prevArrow: $that.find('.js-menu-tab-slider-arrow-left'),
      nextArrow: $that.find('.js-menu-tab-slider-arrow-right'),
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    });
  })
};

export default menuTabSlider;
