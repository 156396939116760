import {ScrollScene} from "scrollscene";

const blockFadeInAnimation = () => {
  $('.js-animate-fade-in').each(function () {
    const that = this;
    const scene = new ScrollScene({
      triggerElement: that,
      triggerHook: 0.8,
      destroyImmediately: true,
      toggle: {
        element: that,
        className: 'js-animated',
        reverse: true
      },
    })
  })
};

export default blockFadeInAnimation;
