import $ from 'jquery';
import 'slick-slider';

const gameZoneSlider = () => {
  $('.js-game-zone-block').each(function () {
    const $that = $(this);
    const $gameZoneSlider = $(this).find('.js-game-zone-slider');

    $gameZoneSlider.on('init reInit afterChange', function (event, slick, currentSlide) {
      const i = (currentSlide || 0) + 1;

      $that.find('.js-game-zone-total-slider').text(slick.slideCount);
      $that.find('.js-game-zone-length-slider').text(`${i}`);
    });

    $gameZoneSlider.slick({
      prevArrow: $(this).find('.js-game-zone-slider-arrow-left'),
      nextArrow: $(this).find('.js-game-zone-slider-arrow-right'),
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false
    });
  })

}

export default gameZoneSlider;
