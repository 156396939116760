import { MESSAGES } from "./copyright";
import CONFIG from "./config";
import useLanguage from "./hooks/useLanguage";
import placesText from "./placesText";
import setTariff from "./helpers/setTariff";
import useList from "./hooks/useList";

const renderWorkingHours = (day, arr, time, currentYear, month) => {
  const lang = useLanguage();
  const list = useList()
  const date = new Date;
  const dayOfWeek = new Date(currentYear, month, day).getDay()
  const pastHours = (() => day === date.getDate() ? date.getHours() + 2 : false)()
  const dates = Object.entries(arr[day])
  const startClock = CONFIG.START_HOURS

  list.innerHTML = ''

  setTimeout(()=>{
    list.parentNode.scrollLeft = 0
  }, 0)

  dates.slice(startClock).concat(dates.slice(0, startClock)).forEach((place) => {
    const currentTime = place[0]
    const currentPlaces = place[1]
    const label = document.createElement('label')
    const checkbox = document.createElement('input')
    const b = document.createElement('b')
    const span = document.createElement('span')
    const text = document.createElement('span')

    text.classList.add('checkbox__text')
    span.classList.add('u-ml-5', 'u-text-sm')
    label.classList.add('checkbox', 'checkbox--md', 'vr-form__checkbox')
    checkbox.classList.add('checkbox__input')
    checkbox.name = "time"
    checkbox.value = String(currentTime)
    checkbox.dataset.time = String(currentTime)
    checkbox.dataset.places = String(currentPlaces)
    checkbox.dataset.tarif = setTariff(dayOfWeek, currentTime)

    b.innerText = time[Number(currentTime) - 1]
    span.innerText = `(${currentPlaces} ${placesText(currentPlaces)})`
    text.append(b)
    text.append(span)
    label.append(text)
    checkbox.setAttribute('type', 'checkbox')

    if (currentPlaces < 1 || pastHours > currentTime) {
      span.innerText = `(${MESSAGES[lang].NO_PLACES})`
      checkbox.setAttribute('disabled', '')
    }

    label.prepend(checkbox)
    list.append(label)
  })
}

export default renderWorkingHours;
