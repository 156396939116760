const setTariff = (day, time) =>{
  let tariff;
  switch (day) {
    case 0:
    case 6:
      tariff = 300
      break;
    case 5:
      if(time > 18) {
        tariff = 300
      } else {
        tariff = 250
      }
      break;
    default:
      tariff = 250
  }

  return tariff
}

export default setTariff
