import getScrollbarWidth from "../helpers/getScrollbarWidth";

const closeModalByEscapeButton = (e, callback, $modal) => {
  if(e.which === 27 || e.which === "Escape") {
    callback($modal);
  }
}

export const closeModal = ($modal) => {
  $modal.addClass('modal-is-hidden');
  $(document).unbind('keydown', closeModalByEscapeButton);
  $('body')
    .removeClass('is-overflow-hidden')
    .css('padding-right', '');
}

export const openModal = ($modal) => {
  const $body = $('body');

  $body.addClass('is-overflow-hidden');
  $body.css('padding-right', getScrollbarWidth());

  $modal.removeClass('modal-is-hidden');
  $modal.addClass('modal-show')
  setTimeout(() => {
    $modal.removeClass('modal-show');
  }, 200);

  $(document).bind('keydown', (e) => closeModalByEscapeButton(e, closeModal, $modal));
  $(document).on('click', '.js-modal-close', function(e){
    if(e.target.className === $(this).attr('class')) {
      closeModal($modal);
    }
  });
}

const modals = () => {
  $(document).on('click', '[data-modal^="#"]', function (){
    openModal($($(this).data('modal')));
  })
}

export default modals
