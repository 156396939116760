import {MONTHS_CASE} from "../copyright";
import useLanguage from "../hooks/useLanguage";

const setModalTitle = (day, month, currentYear) => {
  const lang = useLanguage();
  const title = document.getElementById('order-modal-date');
  const input = document.getElementById('order-modal-date-input');

  input.value = `${currentYear}-${month + 1}-${day}`
  title.innerHTML = `${day} ${MONTHS_CASE[lang][month]} ${currentYear}`
}

export default setModalTitle
