export const MONTHS = {
  "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "ru": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
  "uk": ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
}

export const MONTHS_CASE = {
  "en": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "ru": ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"],
  "uk": ["Січня", "Лютого", "Березеня", "Квітеня", "Травеня", "Червеня", "Липня", "Серпня", "Вересня", "Жовтня", "Листопадя", "Груденя"],
}

export const DAYS = {
  "en": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  "ru": ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
  "uk": ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Нд"],
}

export const MESSAGES = {
  "en": {
    PLACE: "place",
    PLACE_PLURAL: "places",
    PLACE_SINGLE: "place",
    NO_PLACES: "no places"
  },
  "ru": {
    PLACE: "мест",
    PLACE_PLURAL: "места",
    PLACE_SINGLE: "место",
    NO_PLACES: "нет мест"
  },
  "uk": {
    PLACE: "місць",
    PLACE_PLURAL: "місця",
    PLACE_SINGLE: "місце",
    NO_PLACES: "немає місць"
  }
}
