import $ from 'jquery'

const scrollXToCenter = () => {
  const $inner = $('.js-centered-block-wrapper');

  $inner.each(function (index, item){
    const $item = $(item)
    const $container = $item.parent();

    $(window).on('resize',function() {
      $container.animate({
        scrollLeft: (($item.width() - $container.width())/ 2),
      }, 0);
    });
  })

  $(document).ready(function() {
    $(window).trigger('resize');
  });
}

export default scrollXToCenter;
