import $ from 'jquery'

const resumeUpload = () => {
  $('.js-upload').on('change',function(e) {
    $(e.target).next().find('.js-upload-placeholder').text(e.target.files[0].name)
    $(this).parent().addClass('is-uploaded')
  })
  $('.js-upload-reset').on('click', function (){
    document.getElementById($(this).parent().attr("for")).value = ''
    $(this).parent().parent().removeClass('is-uploaded')
  })
}

export default resumeUpload;
