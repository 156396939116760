import {MONTHS, DAYS, MESSAGES} from "./copyright";
import renderWorkingHours from "./renderWorkingHours"
import placesText from "./placesText";
import calculate from "./calculate";

import useLanguage from "./hooks/useLanguage";
import useCurrentMonth from "./hooks/useCurrentMonth";
import useNextMonth from "./hooks/useNextMonth";
import useMaxPlaces from "./hooks/useMaxPlaces"

import setCellToDisable from "./helpers/setCellToDisable";
import getDay from "./helpers/getDay";
import setModalTitle from "./helpers/setModalTitle"

const initCalendar = (month, arr, time, resetInputsCallback) => {
  const calendar = document.getElementById('calendar');

  const lang = useLanguage();
  const current = useCurrentMonth();
  const next = useNextMonth();

  const date = new Date(new Date().getFullYear(), month);
  const currentDay = new Date().getDate()
  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()
  const currentHours = new Date().getHours()

  calendar.innerHTML = ''

  DAYS[lang].forEach(day => {
    const cell = document.createElement('div');
    const wrapper = document.createElement('div')

    cell.classList.add('calendar__cell', 'calendar__cell--day')
    wrapper.classList.add('calendar__wrapper')
    wrapper.innerHTML = day
    cell.append(wrapper)
    calendar.append(cell)
  })

  for (let i = 0; i < getDay(date); i++) {
    const empty = document.createElement('div');
    empty.classList.add('calendar__cell','calendar__cell--empty')
    calendar.appendChild(empty)
  }

  while (date.getMonth() === month) {
    const day = date.getDate();
    const pastDate = day < currentDay && date.getMonth() === currentMonth
    const places = Object.values(arr)[day - 1]

    let filteredPlacesByCurrentHours = {}
    let calculated = 0

    if(day === currentDay) {
      const filtered = Object.entries(arr[currentDay]).filter((key) => Number(key[0]) > currentHours + 1)
      filtered.forEach(([key, value])=>{
        filteredPlacesByCurrentHours[key] = value
      })
    } else {
      filteredPlacesByCurrentHours = places
    }

    if(places !== null) {
      calculated = Object.values(filteredPlacesByCurrentHours).reduce((acc,value) => acc + value)
    }
    const numDiv = document.createElement('div');
    const availableDiv = document.createElement('div');
    const wrapper = document.createElement('div');
    const trigger = document.createElement('button');
    const cell = document.createElement('div');
    const hover = document.createElement('div');
    const hoverInner = document.createElement('div');
    const availablePlacesText =
      calculated === 0
        ? MESSAGES[lang].NO_PLACES
        : `${calculated  } ${placesText(calculated)}`

    hover.classList.add('u-back-pink-hover')
    hoverInner.classList.add('u-back-pink-hover__wrapper')
    cell.classList.add('calendar__cell')
    trigger.setAttribute('type', 'button')
    trigger.classList.add("calendar__trigger")
    wrapper.classList.add("calendar__wrapper")
    numDiv.classList.add("calendar__num")
    availableDiv.classList.add("calendar__available")
    numDiv.innerText = day;
    availableDiv.innerHTML = pastDate ? "": availablePlacesText

    if(pastDate) {
      cell.classList.add("calendar__cell--disabled")
    } else if(day === currentDay && month === currentMonth) {
      cell.classList.add("calendar__cell--today")
    } else {
      cell.classList.add("calendar__cell--count")
    }

    if(calculated > 0 && !pastDate) {
      trigger.append(numDiv)
      trigger.append(availableDiv)
      trigger.dataset.modal = '#order-modal';
      cell.append(trigger)
      hoverInner.append(cell)
      hover.append(hoverInner)
      calendar.append(hover)
    } else {
      wrapper.append(numDiv)
      wrapper.append(availableDiv)
      cell.append(wrapper)
      calendar.append(cell)

      setCellToDisable(calculated, cell);
    }

    trigger.addEventListener('click', function (){
      const maxPlaces = useMaxPlaces(currentDay, day, arr)
      resetInputsCallback();
      renderWorkingHours(day, arr, time, currentYear, month)
      setModalTitle(day, month, currentYear)
      calculate(maxPlaces);
    })

    date.setDate(date.getDate() + 1);
  }

  if (getDay(date) !== 0) {
    for (let i = getDay(date); i < 7; i++) {
      const empty = document.createElement('div');
      empty.classList.add('calendar__cell', 'calendar__cell--empty')
      calendar.append(empty)
    }
  }

  current.innerHTML = `${MONTHS[lang][new Date().getMonth()] } ${currentYear}`
  next.innerHTML = MONTHS[lang][new Date().getMonth() + 1]
}

export default initCalendar
