import $ from 'jquery'

const responsiveTabs = () => {
  const $parent = $('.js-responsive-tab');
  const $tab = $parent.children();
  const count = [];
  let sum;

  $parent.attr('data-title', $tab.children().first().text());
  $tab.children('button').each(function () {
    count.push($(this).children('span').outerWidth(true))
  });
  if($parent.length) {
    sum = count.reduce((a, v) => a + v);
  }


  $(window).on('resize', function () {
    const removeParentClass = () => {
      $parent.removeClass('is-adaptive is-active js-responsive-tab-trigger');
    };

    if(sum > $tab.width()) {
      if($parent.hasClass('is-adaptive js-responsive-tab-trigger')) {
        removeParentClass();
      }
      $parent.addClass('is-adaptive js-responsive-tab-trigger');
    } else {
      removeParentClass();
    }
  })

  $(document).ready(function() {
    $(window).trigger('resize');
  });

  $(document).on('click', '.js-responsive-tab-trigger', function() {
    $(this).children().toggleClass('is-active');
    $(this).toggleClass('is-active');
  });
};

export default responsiveTabs;
