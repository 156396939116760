import CONFIG from "../config";
import useList from "../hooks/useList";
import useCount from "../hooks/useCount";
import useTotal from "../hooks/useTotal";

const setPrices = () => {
  const list = useList();
  const count = useCount();
  const total = useTotal();

  const tariff = [CONFIG.PAYMENT]
  list.querySelectorAll('input[type="checkbox"]').forEach((elem) => {
    if(elem.checked) {
      tariff.push(Number(elem.dataset.tarif))
    }
  })
  total.innerHTML = String(tariff.reduce((acc = 0, item) => (acc + (item * count.value))))
}

export default setPrices;
