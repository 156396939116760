import $ from 'jquery'

const accordion = (headSelector, contentSelector) => {
  if ($(window).width() < 768) {
    $(headSelector).on('click', function () {
      $(this).toggleClass('is-open');
      $(contentSelector).not($(this).siblings()).slideUp(300).siblings().removeClass('is-open');
      $(this).siblings().slideToggle(300);
    })
  }
}

export default accordion;
