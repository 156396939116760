import {closeModal, openModal} from "../modals";
import initCalendar from "./initCalendar";
import useCurrentMonth from "./hooks/useCurrentMonth";
import mockData from "./mockData";
import useNextMonth from "./hooks/useNextMonth";

const postData = (form) => {
  const current = useCurrentMonth();
  const next = useNextMonth();
  const button = form.querySelector('[type="submit"]')
  const endpoint = `${button.dataset.url}`
  const formData = new FormData(form);
  const timeslots = []
  const timeSlotsCalculated = []
  let currentData;
  let clientPhone;

  formData.forEach(function (value, key) {
    if(key === 'time') {
      timeslots.push(Number(value))
      timeSlotsCalculated.push(Number(value) - 1)
    }
    if(key === 'booking_date') {
      currentData = value
    }

    if(key === 'client_phone') {
      clientPhone = value.replace(/\D+/g, '')
    }
  })
  formData.delete('time')
  formData.delete('client_phone')
  formData.append('time_0', `${currentData} ${timeslots[0] - 1}:00`)
  formData.append('client_phone', clientPhone)

  formData.append('time_1', timeslots[1] ?  `${currentData} ${timeslots[1]}:00` : `${currentData} ${timeslots[0]}:00`)
  formData.append('time_slots', timeSlotsCalculated)

  fetch(endpoint, {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then(data => {
      closeModal($('#order-modal'))
      button.classList.remove('is-preloaded')
      openModal($('#success-modal'))

      if(current.classList.contains('is-active')) {
        initCalendar(new Date().getMonth(),data.currentMonth,  data.workingHours, () => {});
      } else {
        initCalendar(new Date().getMonth() + 1, data.nextMonth,  data.workingHours, () => {});
      }

      current.removeEventListener('click', () => {})
      current.addEventListener('click', () => {
        initCalendar(new Date().getMonth(),data.currentMonth,  data.workingHours, () => {});
      })

      next.removeEventListener('click', () => {})
      next.addEventListener('click', () => {
        initCalendar(new Date().getMonth() + 1, data.nextMonth,  data.workingHours, () => {});
      })

    })
    .catch(error => {
      console.log('Error', error)
      if(current.classList.contains('is-active')) {
        initCalendar(new Date().getMonth(), mockData.currentMonth,  mockData.workingHours, () => {});
      } else {
        initCalendar(new Date().getMonth() + 1, mockData.nextMonth,  mockData.workingHours, () => {});
      }
    })
    .finally(() => {
      console.log('success')
    })

}

export default postData
